import styled from "@emotion/styled";
import "../../App.css";
import axios from "axios";

import { useRef, useEffect, useMemo, useState, useContext, useCallback } from "react";

import { useParams } from "react-router-dom";
import { UserContext } from "src/App";
import { getPictureByDescription, getVideoByDescription, myColors, resizeImage, saveDataDB } from "src/api";
import { FormGroup, Modal } from "react-bootstrap";

import { Add, AddAPhoto, AddLink, Close, Edit, OndemandVideo, Send } from "@mui/icons-material";
import MyVideoEditor from "./MyVideoEditor";
import { ButtonL } from "../AppIndex";
import YouTube from "react-youtube";
import MyVideoTrimmer from './MyVideoTrimmer'

const uploadVideoUrl =
  "https://vare-middleware.herokuapp.com/upload/myAppVideoUpload";
const uploadImgUrl =
  "https://vare-middleware.herokuapp.com/upload/myAppImageUpload";

export const MyVideoUploader = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { myInfo, host, eventId, hostTabs } = myContext;
  const { editInfo, type } = props;

  const params: any = useParams<any>();
  const {
    formInput,
    setFormInput,
    handleSaveImage,
    uploadNow = false,
    setRefreshing,
    addFiletoDatabase,
    resource,
    defaultImage = "",
    videoKey = 'video',
    aiSearchBy
  } = props;

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // const resource = useMemo(() => {
  //   const tab = eventTab || hostTab
  //   const subTab = eventSubTab || hostSubTab
  //   return `calendar_${params.eventId ? "event" : "host"
  //     }${tab ? '_' + tab : ''}${subTab ? '_' + subTab : ''}`
  // }, [
  //   eventTab,
  //   eventSubTab,
  //   hostSubTab,
  //   hostTab,
  //   params?.eventTab
  // ]);

  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );

  const fileVideoInputRef = useRef<any>();
  const videoFile = useRef<any>();

  const [selectedImage, setSelectedImage] = useState<any>('');
  const [progress, setProgress] = useState("getUpload");
  const [errorMessage, setErrorMessage] = useState("");
  const [formInputs, setFormInputs] = useState<any>({});

  const [loadType, setLoadType] = useState<any>('')

  const handleImageClick = () => {
    if (fileVideoInputRef.current) {
      fileVideoInputRef.current.click();
    }
  };

  useEffect(() => {
    // alert(formInput?.video)
    setSelectedImage(formInput?.video || '')
  }, [
    formInput?.video
  ])

  useEffect(() => {
    (async () => {
      // console.log('videoFile.current', videoFile.current)

      if (uploadNow && !resource) {
        alert("Resource not found");
        return
      }
      if (uploadNow && videoFile.current?.name) {
        const fileName = `calendar_${videoFile.current?.name?.toLowerCase()}`;
        const info: any = {
          handle: myInfo?.handle,
        };

        setRefreshing(true);
        if (addFiletoDatabase) {
          return await postVideo(fileName)
            .then((img: any) => {
              addFiletoDatabase(
                `https://varefiles.s3.us-east-2.amazonaws.com/${img?.data && img.data.key ? img.data.key : fileName}`
              );
            });
        } else {

          // return
          const imageUploader = await postVideo(fileName)
            .then((img: any) => {
              info["video"] = `https://varefiles.s3.us-east-2.amazonaws.com/${img?.data && img.data.key ? img.data.key : fileName}`;
              return true;
            })
            .catch((err) => {
              return true;
            });
          if (imageUploader) {
            const data = {
              query: {
                ...formInput,
                ...info,
              },
              resource: resource,
              check: ["handle", "title"],
              myInfo,
            };
            // console.log("hostTabsxxccbbbb vv", data);

            saveDataDB(data).then((res) => {
              setMyContext((existing: any) => {
                return {
                  ...existing,
                  editInfo: false,
                };
              });
            });
          }
        }
      }
    })();
  }, [
    videoFile.current,
    resource,
    uploadNow,
    myInfo?.handle
  ]);

  const postVideo = async (fileName: any) => {
    if (!videoFile.current) {
      return;
    }

    const formData = new FormData();
    formData.append("document", videoFile.current, fileName);
    // console.log('cxxxx', videoFile.current)
    return await axios.post(uploadVideoUrl, formData)
      .then(res => {
        // setLoaded({ ...loaded, video: false });
        return {}
      })
      .catch(err => {
        console.log("VideoError", err);
      });
    setProgress("uploaded");
  };

  const getVideoFile = async (file: any) => {
    var input = file.target;
    if (file) {
      var reader = new FileReader();
      reader.onload = async () => {
        var dataURL = reader.result;
        // var output: any = document.getElementById("output");
        // output.src = dataURL ? dataURL : "";
        console.log(dataURL, "ssss");
        setSelectedImage(dataURL);

        // console.log('fileImageName', input?.files[0]?.name)

        const successImages: any = reader.result;

        if (!uploadVideoUrl) {
          setErrorMessage("missing a url to upload to");
          setProgress("uploadError");
          return;
        }
        setProgress("uploading");
        try {
          const name: any = input?.files[0]?.name;
          // videoFile.current = input?.files[0];
          videoFile.current = file.target.files[0];


          // const imageString = await resizeImage(successImages, 200, 200);
          const image =
            name &&
              (name.includes(".mp4") ||
                name.includes(".mov") ||
                name.includes(".mp5"))
              ? successImages
              : successImages;

          const parts = successImages.split(";");
          const mime = parts[0].split(":")[1];

          fileImageName.current = name;
          fileImageData.current = image[0];
          fileImageMime.current = mime;
          setProgress("uploaded");
        } catch (error) {
          console.log("error in upload", error);
          // @ts-ignore
          setErrorMessage(error.message);
          setProgress("uploadError");
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  // console.log('uploadVideoUrl', fileVideoInputRef, videoFile.current)

  const [myVideos, setMyVideos] = useState<any>([])
  const [savedLink, setSavedLink] = useState<any>('')
  const [loadingAI, setLoadingAI] = useState<boolean>(false)

  const [formInputAI, setFormInputAI] = useState<any>('')
  const [formInputVideo, setFormInputVideo] = useState<any>('')

  // const videoDescription = useMemo(() => {
  //   return formInput?.title
  // }, [
  //   formInput?.title
  // ])
  const getPics = useCallback((videoDescription: any) => {
    if (!(videoDescription && myInfo?.handle)) {
      alert('Please login')
      setLoadingAI(false)
      return
    }
    // alert(3 + videoDescription)
    getVideoByDescription({
      description: videoDescription,
      myInfo: myInfo
    })
      .then((info: any) => {
        console.log('vidoes found:', info);

        if (info?.[0]) {
          setMyVideos(info?.map((reg: any) => {
            return {
              img: reg.image,
              image: reg.image,
              video: reg.url,
              duration: reg?.duration
            }
          }))
          setLoadingAI(false)
        } else {
          setLoadingAI(false)

          console.log('No picture found for the description');
        }
      }).catch(err => {
        alert('err')
      })

  }, [
    myInfo?.handle
  ])

  const youtubeKey = useMemo(() => {
    const info = formInput?.[videoKey]?.video?.includes('youtube') && formInput?.[videoKey]?.split('=')
    return info?.[info?.length - 1]
  }, [
    formInput?.[videoKey]
  ])

  const opts = {
    // height: '200',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0, // Prevents autoplay
      controls: 1, // Show player controls
      rel: 0, // Prevent showing related videos at the end
      // autoplay: 1,
    },
  };

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
        {/* {loadType && <Close onClick={() => {
          setLoadType(false)
        }} />} */}

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>

          {loadType == 'AI'
            ? <> <div style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 14,
              background: "rgb(71 157 131 / 25%)",
              margin: "10px 0px",
              position: "relative",
              borderRadius: 5

            }}>
              {loadType && <Close style={{
                position: "absolute",
                top: 5,
                right: 5,
                width: 16,
                height: 16
              }} onClick={() => {
                setLoadType(false)
              }} />}
              <input
                // value={data?.title}
                className="form-control"
                type="text"
                onChange={(e: any) =>
                  setFormInputAI(e.target?.value)
                }
                value={formInputAI || ""}
                style={{
                  border: '2px solid #479d83',
                  // marginBottom: 10,
                  width: "100%",
                  alignSelf: "center",
                  // backgroundColor: 'gray'
                }}
                required
                placeholder="Generate or Search Video"
              />
              <div
                onClick={() => {
                  if (!(formInputAI?.length > 3)) {
                    alert(`A keyword or description is needed to generate your description`)
                    return
                  }
                  setLoadingAI(true)
                  getPics(formInputAI)
                }}
                style={{
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: loadingAI ? 'green' : 'transparent',
                  borderRadius: 100,
                  color: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                  marginRight: 10,
                }}

              >
                {loadingAI ? 'loading..' : <Send
                  style={{
                    color: formInputAI?.length > 3 ? 'green' : 'gray',
                  }} />}
              </div>
            </div>




              {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                border: '2px solid #479d83',
                borderRadius: 20
              }}
            >
              <div
                style={{
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'green',
                  borderRadius: 100,
                  color: 'white',
                  paddingRight: 5,
                  paddingLeft: 5,
                  marginRight: 10,
                }}
                onClick={() => {
                  if (aiSearchBy && !formInput[aiSearchBy] || !formInput?.title) {
                    alert(`A ${aiSearchBy && !formInput[aiSearchBy] ? aiSearchBy : 'title '} is needed to generate your description`)
                    return
                  }
                  setLoadingAI(true)
                  getPics()
                }}
              >
                {loadingAI ? 'loading..' : 'AI'}
              </div>
              <div>Generate or Search Video</div>
            </div> */}
            </>
            : loadType == 'link'
              ? <InputAgenda style={{
                marginTop: 10,
                marginBottom: 10
              }}>
                {loadType && <Close onClick={() => {
                  setLoadType(false)
                }} />}
                <input
                  type={"text"}
                  onChange={(e: any) => {
                    setFormInputVideo(e.target?.value)
                  }}
                  value={formInputVideo || ''}
                  placeholder={"Add image url ..."}
                  required
                  style={{
                    border: '2px solid #479d83',
                    borderRadius: 20,
                    width: '100%'
                  }}
                />
                <Send
                  style={{
                    color: (formInputVideo?.includes('.mp4')) ? 'green' : 'black'
                  }}
                  onClick={() => {
                    if (!formInputVideo?.includes('.mp4')) {
                      alert('Oops video url seem wrong, please try again')
                      return
                    }
                    setFormInput({
                      ...formInput,
                      video: formInputVideo,
                    })
                    setLoadType(false)
                  }}
                />
              </InputAgenda>
              : loadType == 'edit'
                ? <Modal
                  style={{
                    // marginTop: 20,
                    display: 'flex',
                    zIndex: 9999999
                  }}
                  show={true}
                  onHide={() => {
                    setLoadType(false)
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>MyGuestNet</Modal.Title>
                  </Modal.Header>
                  <Modal.Body> Kiosk</Modal.Body>
                  <Modal.Footer
                    style={{
                      display: "flex",
                      width: "80% !important",
                      // justifyContent: 'center',
                      // alignItems: 'center',
                    }}
                  >
                    <MyVideoTrimmer
                      {...props}
                    />
                  </Modal.Footer>
                </Modal>
                : null}
          <InputAgenda>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              // backgroundColor: 'red',

            }}>
              {!formInput?.[videoKey] && myVideos?.[0]
                && <div style={{
                  display: 'flex',
                  padding: 20,
                  width: 400,
                  overflowX: 'auto',
                  flexDirection: 'row',
                  gap: 10,
                  backgroundColor: 'rgba(0,0,0,0.4)'
                }}>
                  {myVideos?.map((media: any, index: number) => {
                    console.log('media', media)
                    return <div style={{
                      display: 'flex',
                      flexDirection: "column",
                      // width: 400
                    }}>
                      <video
                        // autoPlay
                        controls
                        // loop
                        muted
                        poster={
                          media?.image ||
                          "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      >
                        <source src={media?.video} type="video/mp4" />
                        <source src={media?.video} type="video/ogg" />
                      </video>
                      <ButtonL
                        style={{
                          margin: 0,
                          ...(!formInput?.topic ? {
                            backgroundColor: 'rgba(0,0,0,0.1)'
                          } : {}),
                          color: 'white'
                        }}
                        onClick={() => {
                          if (!myInfo?.handle) {
                            alert("Please login");
                            return;
                          }
                          // if (!formInput?.title) {
                          //   alert("Title required");
                          //   return;
                          // }
                          setFormInput({
                            ...formInput,
                            [videoKey]: media?.video, //index?.toString()
                            videoImage: media?.image,
                            videos: myVideos
                          })
                          alert('Saved')
                        }}
                      >
                        {savedLink?.includes(index) ? 'Saved' : 'Add+'}
                      </ButtonL>
                    </div>
                  })}
                </div>
              }

              <ImgWrapper style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                {formInput?.[videoKey] ? (
                  <>
                    {youtubeKey ?
                      <YouTube
                        videoId={youtubeKey}
                        opts={opts}
                      // onReady={onPlayerReady}
                      // onStateChange={handleStateChange}
                      />
                      :
                      <video
                        // autoPlay
                        controls
                        // loop
                        muted
                        poster={
                          formInput?.videoImage ||
                          "https://varefiles.s3.us-east-2.amazonaws.com/myguestnet.mp4"
                        }
                        style={{
                          width: "100%",
                          height: 200,
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      >
                        <source src={formInput?.[videoKey]} type="video/mp4" />
                        <source src={formInput?.[videoKey]} type="video/ogg" />
                      </video>}
                  </>
                )
                  : selectedImage ? (
                    <video
                      onClick={() => {
                        handleImageClick();
                        console.log("clicked");
                      }}
                      controls
                      // autoPlay
                      id="output"
                      className="uploader__file"
                      poster="https://varefiles.s3.us-east-2.amazonaws.com/video-icon.png"
                      src={selectedImage}
                      style={{
                        width: 80,
                        height: 80
                      }}
                    />
                  ) : (
                    null
                  )}

                <div style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 50,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <div onClick={() => setLoadType('link')} style={{
                    height: 30,
                    width: 30
                  }}>
                    <AddLink />
                  </div>
                  <div onClick={() => setLoadType('edit')} style={{
                    height: 30,
                    width: 30
                  }}>
                    <Edit />
                  </div>
                  <div onClick={() => handleImageClick()} style={{
                    height: 30,
                    width: 30
                  }}>
                    <AddAPhoto />
                  </div>
                  <div onClick={() => setLoadType('AI')} style={{
                    height: 30,
                    width: 30,
                    display: 'flex',
                    borderRadius: 100,
                    backgroundColor: myColors?.brown,
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    AI
                  </div>
                </div>
                {/* <UploadImg onClick={handleImageClick}>
              <OndemandVideo />
            </UploadImg> */}
                <input
                  ref={fileVideoInputRef}
                  className="form-control"
                  type="file"
                  name={"file"}
                  accept="video/*"
                  onChange={getVideoFile}
                />
              </ImgWrapper>
            </div>
          </InputAgenda>


        </div>

      </div>
    </div>
  </>
};

export const InputAgenda = styled.form`
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 5px;
            border: 1px solid #dee2e6;
            border-radius: 5px;
            background: #fff;
            input {
              outline: none;
            border: none;
            font-size: 14px;
            width: 100%;
  }
            button {
              border: none;
            background: transparent;
            outline: none;
            color: gray;
            transition: color 0.3s;
            svg {
              width: 20px;
            height: 20px;
    }
            &:hover {
              color: #dee2e6;
    }
  }
            `;

const LinkParent = styled.div`
            display: flex;
            gap: 10px;
            align-items: center;
            flex-wrap: wrap;
            `;

const LinkC = styled.a`
            width: 80px;
            height: 80px;
            svg {
              width: 100%;
            height: 100%;
  }
            `;

const UploadImg = styled.div`
            background: #a0ddcb;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            svg {
              color: white;
            width: 40px;
            height: 40px;
  }
            input {
              display: none;
  }
            `;
const ImgWrapper = styled.div`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            input {
              display: none;
  }

            img {
              width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
  }
            video {
              width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
  }
            `;
const FormWrapper = styled(FormGroup)`
            display: flex;
            min-width: 300px;
            flex-direction: column;
            gap: 10px;
            `;
