import React, { Suspense } from 'react';
import "./App.css";
//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Calendar from "react-calendar";
import {
  getCalendarEvents,
  getCalendarSchedule,
  getCalendarUsers,
  getDataDB,
  getUserInfoByHandle,
  handleCalendar,
  saveAppointment,
  saveDataDB,
  sendEmail,
} from "./api";
// import { Navbar } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import AdvancedForms from "./components/AdvancedForms/AdvancedForms";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";

import {
  BrowserRouter,
  createBrowserRouter,
  Link,
  Route,
  BrowserRouter as Router,
  RouterProvider,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { AppIndex } from "./components/AppIndex";
import { CalendarCard } from "./components/CalendarCard";
import Chat from "./components/Chat";
import { EventForm } from "./components/EventForm";
import { HeaderMenu } from "./components/HeaderMenu";
import Menubar from "./components/Menubar";
import { SearchEventForm } from "./components/SearchEventForm";
import { TimeCard } from "./components/TimeCard";
// import VideoPlayerCard from "./components/VideoPlayerCard-na";
import { Close, Description } from "@mui/icons-material";
import { MotionConfig } from "framer-motion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RecoilRoot } from "recoil";
import { DEFAULT_EASE } from "src/common/constants/easings";
import AkComp from "./AkComp";
import CheckoutPage from "./components/CheckoutPage";
import CommentsPlayerCard from "./components/CommentsPlayerCard";
import Tasks from "./components/MediaSlider/Tasks";
import SupportersList from "./components/SupportersList";
import HomePage from "./pages";
import RoomPage from "./pages/roomId";
// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import {
  BackDrop,
  DetailModal,
  MessengerModalMob,
  UserProfileModal,
} from "./components/MediaSlider/UserProfileModal";
// import GuestPage from "./components/GuestPage/GuestPage";
import { TawkMessengerReactInfo } from "./components/TawkMessengerReactInfo";
// import GoogleCalendarAPI from "./GoogleCalendarAPI";
// import SideAppDrawer from "./components/GuestPage/SideAppDrawer";
// import Guestsite from "./components/Guestsite";
// import StoryTemplate from "./components/MediaSlider/StoryTemplate";
// import MyVideoEditor from "./components/MediaSlider/MyVideoEditor";
// import Guestsite from "./components/Guestsite";
import StoryCaption from './components/MediaSlider/StoryCaption';
import { MyAlert } from "./components/MyAlert";
// import InstagramStory from './components/MediaSlider/StoryCaption';
import talkingHeadAnimation from "./assets/talking_head.json";
import InstagramStory from './components/MediaSlider/MultiStoryCaption';
import TalkingHead from './components/TalkingHead';

const StoryTemplate = React.lazy(() => import('./components/MediaSlider/StoryTemplate'));
const Guestsite = React.lazy(() => import('./components/Guestsite'));
const MyVideoEditor = React.lazy(() => import('./components/MediaSlider/MyVideoEditor'));
const GoogleCalendarAPI = React.lazy(() => import('./GoogleCalendarAPI'));
const SideAppDrawer = React.lazy(() => import('./components/GuestPage/SideAppDrawer'));
const GuestPage = React.lazy(() => import('./components/GuestPage/GuestPage'));
const VareApp = React.lazy(() => import('./components/VareApp'));
const NewsRunner = React.lazy(() => import('./components/NewsRunner'));


export const UserContext: any = createContext({});

const colors = {
  lightBlue: "#c1d8ff",
};

export const eventTabs = [
  // {
  //   name: "detail",
  //   title: "Details",
  //   description: "Event Details",
  //   icon: "fa fa-info-circle",
  //   category: "development",

  //   // editButton: true,
  //   // isAdminRequired: true,
  //   // defaultEditor: true,
  //   // subTab: eventDetailsTabs
  // },
  {
    name: "schedule",
    title: "Schedule",
    description: "Event Calendar",
    icon: "fa fa-calendar-alt",
    editButton: true,
    isAdminRequired: true,
    story: true,
    // resource: "calendar_events",
    resource: "calendar_event_schedule",
    category: "operation",
  },
  {
    name: "services",
    title: "Services",
    description: "Enterprise Services",
    icon: "fa fa-calendar-alt",
    editButton: true,
    isAdminRequired: true,
    resource: "calendar_services",
    category: "marketing",
  },
  {
    name: "checkin",
    title: "Checkin",
    description: "Checkin to my scheduled Event, Project or Service",
    icon: "fa fa-check-square",
    resource: "calendar_event_schedule",
    editButton: true,
    isAdminRequired: true,
    // resource: "calendar_event_schedule",
    category: "development",
    advance: true,

    // editButton: true,
  },
  {
    name: "media",
    title: "Media",
    description: " Event Related media",
    icon: "fa fa-camera",
    resource: "calendar_event_media",
    category: "operation",
    editButton: true,
    defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "guest",
    title: "guest",
    description: "Event Contacts",
    icon: "fa fa-users",
    category: "development",
    resource: "calendar_attendance",
    // hostOnly: true,
    isAdminRequired: true,
    advance: true,
  },
  {
    name: "ebenchmark",
    title: "Event Benchmark",
    description: "Event/Project/Service Benchmark",
    icon: "fa fa-chart-bar",
    resource: "calendar_event_benchmark",
    editButton: true,
    // defaultEditor: true,
    isAdminRequired: true,
    category: "marketing",
    advance: true,

    // defaultEditor: true,
    // editButton: true,
    // isAdminRequired: true
  },
  {
    name: "userjourney",
    title: "User Journey",
    description: "User Journey",
    icon: "fa fa-user-circle",
    resource: "calendar_event_journey",
    category: "development",
    editButton: true,
    // defaultEditor: true,
    isAdminRequired: true,
    advance: true,

    // defaultEditor: true,
    // editButton: true,
    // isAdminRequired: true
  },
  // {
  //   name: "userStories",
  //   title: "News Runners",
  //   description: "News Runners",
  //   icon: "fa fa-newspaper",
  //   resource: "calendar_event_userstories",
  //   category: "marketing",
  //   editButton: true,
  //   isAdminRequired: true,
  //   advance: true,

  //   // subTab: [
  //   //   {
  //   //     name: "create",
  //   //     title: "Create story",
  //   //     description: "Create stories",
  //   //     icon: "fa fa-edit",
  //   //     resource: "calendar_event_stories",
  //   //     editButton: true,
  //   //     // defaultEditor: true,
  //   //     isAdminRequired: true,
  //   //   },
  //   //   {
  //   //     name: "stories",
  //   //     title: "Stories",
  //   //     description: "Stories",
  //   //     icon: "fa fa-book",
  //   //     resource: "calendar_event_stories",
  //   //     editButton: true,
  //   //     // defaultEditor: true,
  //   //     isAdminRequired: true,
  //   //   },
  //   // ],
  // },
  {
    name: "ticket",
    title: "Ticket",
    description: "Event Tickets",
    icon: "fa fa-ticket-alt",
    resource: "calendar_event_ticket",
    editButton: true,
    category: "marketing",
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "subscription",
    title: "Subscription",
    description: "Event Subscription",
    icon: "fa fa-credit-card",
    resource: "calendar_event_subscription",
    editButton: true,
    category: "marketing",
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "payment",
    title: "Payment",
    description: "Get Paid",
    icon: "fa fa-credit-card",
    resource: "calendar_event_payment",
    editButton: true,
    category: "development",
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "catalog",
    title: "Catalog",
    description: "Catalog",
    resource: "calendar_event_products",
    icon: "fa fa-store-alt",
    category: "marketing",
    // editButton: true,
    isAdminRequired: true,
    advance: true,
  },
  {
    name: "request",
    title: "Service Request",
    icon: "fa fa-list",
    resource: "calendar_event_services",
    category: "marketing",
    // editButton: true,
    isAdminRequired: true,
    advance: true,
  },
  {
    name: "coupon",
    title: "Coupon",
    description: "Guest coupons",
    icon: "fa fa-percent",
    resource: "calendar_event_coupon",
    editButton: true,
    category: "marketing",
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "sponsor",
    title: "Sponsor",
    description: "Sponsor adverts at GuestNet events or member apps",
    icon: "fa fa-bullhorn",
    hastags: ["advert", "promote", "invest"],
    resource: "calendar_event_advert",
    category: "marketing",
    advance: true,
    // editButton: true,
    isAdminRequired: true,
    subTab: [
      {
        name: "accept",
        title: "Accept Sponsors Adverts",
        description: "Event Subscription",
        icon: "fa fa-check-circle",
        resource: "calendar_event_advert",
        // editButton: true,
        // defaultEditor: true,
        isAdminRequired: true,
      },
      {
        name: "create",
        title: "Create Sponsorship Adverts",
        description: "Create Sponsorship Adverts",
        icon: "fa fa-pencil-alt",
        resource: "calendar_event_advert",
        editButton: true,
        // defaultEditor: true,
        isAdminRequired: true,
      },
    ],
  },
  {
    name: "todo",
    title: "Todo",
    description: "Company todo list Planner",
    icon: "fa fa-tasks",
    resource: "calendar_event_todo",
    category: "development",
    // editButton: true,
    hostOnly: true,
    isAdminRequired: true,
    editButton: true,
    // advance:true
  },
  {
    name: "funnel",
    title: "Lead Funnel",
    description: "Lead Funnel",
    icon: "fa fa-store",
    resource: "calendar_event_funnel",
    category: "development",
    editButton: true,
    isAdminRequired: true,
    defaultEditor: true,
  },
  {
    name: "planner",
    title: "Planner",
    description: "Leads Manager",
    icon: "fa fa-users",
    resource: "calendar_event_kanban",
    category: "development",
    editButton: true,
    isAdminRequired: true,
  },
  {
    name: "reviews",
    title: "Reviews",
    description: "Rate Product or Service",
    category: "development",
    // editButton: true,
    isAdminRequired: true,
    resource: "calendar_event_reviews",
    advance: true,
    icon: "fa fa-star",
  },
  {
    name: "brainstorm",
    title: "Brainstorm",
    description: "Write or draw your ideas",
    icon: "fa fa-lightbulb",
    resource: "calendar_event_brainstorm",
    category: "development",
    editButton: true,
    isAdminRequired: true,
    advance: true,
  },
  // {
  //   name: "contentCreator",
  //   title: "Content Creator",
  //   description: "Write or draw your ideas",
  //   icon: "fa fa-lightbulb",
  //   resource: "calendar_event_contentCreator",
  //   category: "development",
  //   editButton: true,
  //   isAdminRequired: true,
  //   advance: true,
  // },
  {
    name: "reports",
    title: "Reports",
    icon: "fa fa-file-alt",
    category: "development",
    // editButton: true,
    isAdminRequired: true,
    advance: true,
    subTab: [
      {
        name: "forms",
        title: "Forms",
        description: "Custom Reports",
        icon: "fa fa-file",
        resource: "calendar_event_form",
        editButton: true,
        isAdminRequired: true,
      },
      {
        name: "data",
        title: "Tables & Chart",
        description: "Form Reports",
        icon: "fa fa-table",
        resource: "calendar_event_form_table",
        editButton: true,
        isAdminRequired: true,
      },
      {
        name: "import",
        title: "Import",
        description: "Import excel/csv",
        icon: "fa fa-file-import",
        resource: "calendar_event_form_csv",
        editButton: true,
        isAdminRequired: true,
      },
    ],
  },
  {
    name: "signdocument",
    title: "Agreement",
    icon: "fa fa-file-signature",
    category: "development",
    isAdminRequired: true,
    advance: true,
    subTab: [
      {
        name: "agreements",
        title: "Agreements",
        description: "Agreements (Under development)",
        icon: "fa fa-tags",
        resource: "calendar_event_agreements",
        editButton: true,
        isAdminRequired: true,
        protected: true,
      },
      {
        name: "documentsign",
        title: "Sign Document",
        description: "Signed",
        icon: "fa fa-pen-square",
        resource: "calendar_event_sign",
        editButton: true,
        isAdminRequired: true,
        protected: true,
      },
    ],
    // defaultEditor: true,
  },
  {
    name: "interest",
    title: "Skills & Resources Needed",
    description: "Resources (Skills) needed by Host",
    resource: "calendar_event_resource_needs",
    icon: "fa fa-tools",
    category: "operation",
    advance: true,
    editButton: true,
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "training",
    title: "Training Programs",
    description: "Our Training Prohramgs",
    resource: "calendar_event_training",
    icon: "fa fa-tools",
    category: "operation",
    advance: true,
    editButton: true,
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "ourSkills",
    title: "Our Skills",
    description: "Our in house (Skills)",
    category: "operation",
    editButton: true,
    isAdminRequired: true,
    resource: "calendar_event_new_resource",
    advance: true,
  },
  {
    name: "comments",
    title: "Comments",
    description: "Event Comments",
    resource: "calendar_event_coments",
    icon: "fa fa-comment",
    category: "development",
    advance: true,
  },
  {
    name: "faq",
    title: "Frequently asked questions",
    description: "Frequently asked questions",
    icon: "fa fa-question-circle",
    resource: "calendar_event_faq",
    category: "development",
    advance: true,
    editButton: true,
    isAdminRequired: true,
    // defaultEditor: true,
  },
  {
    name: "policy",
    title: "Policies",
    icon: "fa fa-file-alt",
    description: "Our Policies",
    resource: "calendar_policies",
    category: "development",
    advance: true,
    editButton: true,
    isAdminRequired: true,
    // defaultEditor: true,
  },

  {
    name: "incident",
    title: "Incident / Report",
    description: " Incident Report",
    icon: "fa fa-exclamation-circle",
    editButton: true,
    resource: "calendar_incident_report",
    category: "development",
    advance: true,
    isAdminRequired: true,
  },
  {
    name: "emailer",
    title: "Email Campaign",
    description: " Design campaign email",
    icon: "fa fa-envelope",
    resource: "calendar_event_emaileditor",
    category: "development",
    advance: true,
    editButton: true,
    // defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "watchers",
    title: "Watchers",
    description: "Watchers",
    icon: "fa fa-eye",
    category: "operation",
    resource: "calendar_event_likes",
    editButton: false,
    isAdminRequired: true,
  },
  {
    name: "collaborators",
    title: "Collaborators",
    description: "Collaborators with Admin rights",
    icon: "fa fa-user-shield",
    category: "operation",
    resource: "calendar_event_collaborators",
    editButton: true,
    isAdminRequired: true,
  },
  {
    name: "members",
    title: "Members",
    description: "Verified Members",
    resource: "calendar_event_members",
    category: "operation",
    editButton: true,
    isAdminRequired: true,
    icon: "fa fa-user-tag",
  },

];



const hostTabs = [
  {
    name: "about",
    title: "About",
    description: "Skills & Bio",
    icon: "fa fa-user-circle",
    resource: "calendar_skills_record",
    editButton: true,
    subTab: [
      {
        name: "view",
        title: "View About",
        icon: "fa fa-tags",
        editButton: true,
        // defaultEditor: true,
      },
      {
        name: "details",
        title: "Skill Details",
        icon: "fa fa-tags",
        editButton: true,
        // defaultEditor: true,
      },
    ],
  },
  {
    name: "media",
    title: "Media",
    description: "Related Media",
    icon: "fa fa-images",
    resource: "calendar_host_media",
    editButton: true,
    defaultEditor: true,
  },
  {
    name: "portfolio",
    title: "Portfolio",
    description: "My Portfolio",
    icon: "fa fa-folder-open",
    resource: "calendar_host_portfolio",
    editButton: true,
    defaultEditor: true,
  },
  {
    name: "certifications",
    title: "Certifications",
    description: "My Certifications",
    icon: "fa fa-folder-open",
    resource: "calendar_host_certifications",
    editButton: true,
    defaultEditor: true,
    isAdminRequired: true,
  },
  {
    name: "link",
    title: "Links",
    description: "Link tree",
    icon: "fa fa-link",
    resource: "calendar_host_link",
    editButton: true,
    defaultEditor: true,
  },
  {
    name: "myActivities",
    title: "My Activities",
    description: "MyActivities",
    icon: "fa fa-star",
    resource: "calendar_events",
    advance: true,
    hostOnly: true,
    editButton: true,
    defaultEditor: true,
    subTab: [
      {
        name: "myEvents",
        title: "My Events",
        description: "My Events",
        resource: "calendar_events",
        hostOnly: true,
        icon: "fa fa-store",
        editButton: false,
      },
      {
        name: "myCollaborators",
        title: "My Collaborators",
        description: "My Collaborators",
        resource: "calendar_events",
        hostOnly: true,
        icon: "fa fa-store",
        editButton: false,
      },
      {
        name: "myMemberships",
        title: "My Memberships",
        description: "My Memberships",
        resource: "calendar_events",
        // hostOnly: true,
        icon: "fa fa-store",
        editButton: true,
      },
    ],
  },
  {
    name: "myMarkets",
    title: "My Markets",
    description: "My Tikets",
    hostOnly: true,
    icon: "fa fa-store",
    resource: "calendar_host_tiket",
    advance: true,
    // editButton: true,
    subTab: [
      {
        name: "myTikets",
        title: "My Tikets",
        description: "My Tikets",
        hostOnly: true,
        icon: "fa fa-store",
        resource: "calendar_host_tiket",
        // editButton: true,
      },
      {
        name: "myCoupons",
        title: "My Coupons",
        description: "My Coupons",
        hostOnly: true,
        icon: "fa fa-store",
        resource: "calendar_host_coupon",
        // editButton: true,
      },
      {
        name: "mySubscription",
        title: "My Subscriptions",
        description: "My Subscriptions",
        resource: "calendar_host_subscription",
        icon: "fa fa-store",
        // editButton: true,
      },
    ],
  },
  {
    name: "myComments",
    title: "My Comments",
    description: "My Comments",
    resource: "calendar_comments",
    hostOnly: true,
    advance: true,
    icon: "fa fa-store",
    editButton: true,
  },
  {
    name: "planner",
    title: "Planner",
    description: "Lead Funnel",
    icon: "fa fa-images",
    editButton: true,
    hostOnly: true,
    isAdminRequired: true,
    subTab: [
      {
        name: "todo",
        title: "Todo",
        description: "Related Media",
        icon: "fa fa-images",
        editButton: true,
        isAdminRequired: true,
        resource: "calendar_host_todo",
      },
      {
        name: "funnel",
        title: "Lead Funnel",
        description: "Lead Funnel",
        icon: "fa fa-store",
        resource: "calendar_event_funnel",
        editButton: true,
        isAdminRequired: true,
        defaultEditor: true,
      },
    ],
  },
  // {
  //   name: "userStories",
  //   title: "News Runners",
  //   description: "News Runners",
  //   icon: "fa fa-chart-bar",
  //   resource: "calendar_host_userstories",
  //   editButton: true,
  //   advance: true,
  //   // defaultEditor: true,
  //   isAdminRequired: true,
  //   subTab: [
  //     {
  //       name: "create",
  //       title: "Create story",
  //       description: "Create stories",
  //       icon: "fa fa-credit-card",
  //       resource: "calendar_host_stories",
  //       editButton: true,
  //       hostOnly: true,
  //       // defaultEditor: true,
  //       isAdminRequired: true,
  //     },
  //     {
  //       name: "stories",
  //       title: "Stories",
  //       description: "Stories",
  //       icon: "fa fa-ticket-alt",
  //       resource: "calendar_host_stories",
  //       editButton: true,
  //       // defaultEditor: true,
  //       isAdminRequired: true,
  //     },
  //   ],
  // },
];

export default function App() {
  const [width, setWidth] = useState(window.innerWidth);
  // const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [myStore, setMyStore] = useLocalStorage<any>("name", {});
  const refEvent = useRef<any>();
  let params: any = useParams();

  const [myContext, setMyContext] = useState<any>({
    isSidebarOpen: false,
    myInfo: {},
    isMenuOpen: false,
    menu: [
      {
        // icon: <Icon
        //     sx={{ width: 30, height: 30 }}
        // />,
        item: "Login",
        route: () => {
          console.log("Training");
          window.location.href =
            "https://rise.articulate.com/share/GjCLWbrSFfUowcqhgq8JNisI74cbaDQE";
        },
      },
      {
        // icon: <SettingsPowerIcon
        //     sx={{ width: 30, height: 30 }}
        // />,
        item: "Logout",
        route: () => {
          console.log("Logout");
          window.location.replace(
            "https://value-tracker.graybar.com/api/logout"
          );
        },
      },
    ],
  });

  useEffect(() => {
    const myI = myStore?.myInfo?.token
      ? {
        ...myStore?.myInfo,
        handle: myStore?.myInfo?.handle || myStore?.myInfo?.email,
      }
      : {};


    // console.log('vvvvxxbbb', typeof myI)
    setMyContext({
      ...myContext,
      myInfo: { ...myI },
      refEvent,
      eventTabs,
      hostTabs,
    });
  }, [myStore?.myInfo]);

  // const {
  //   isSidebarOpen
  // } =myContext

  useEffect(() => {
    if (!myContext?.myInfo?.handle) {
      return;
    }
    // alert(myContext?.myInfo?.handle)
    const dataFunnel = {
      query: {
        handle: myContext?.myInfo?.handle,
      },
      resource: `calendar_event_funnel`,
      check: ["handle"],
    };
    getDataDB(dataFunnel).then((res) => {
      // console.log("vvvv", res?.[0]);
      setMyContext((existing: any) => {
        return {
          ...existing,
          eventFunnelType: res?.[0] || [],
        };
      });
    });

    const dataFunnelHost = {
      query: {
        handle: myContext?.myInfo?.handle,
      },
      resource: `calendar_host_funnel`,
      check: ["handle"],
    };
    getDataDB(dataFunnelHost).then((res) => {
      // console.log("vvvv", res?.[0]);
      setMyContext((existing: any) => {
        return {
          ...existing,
          hostFunnelType: res?.[0] || [],
        };
      });
    });

    const dataFunnelInfo = {
      query: {
        handle: myContext?.myInfo?.handle,
      },
      resource: `calendar_events`,
      check: ["handle"],
    };
    getDataDB(dataFunnelInfo)
      .then((res) => {
        // console.log("vvvv", res?.[0]);
        setMyContext((existing: any) => {
          return {
            ...existing,
            mySchEvents: res?.[0] ? res : [],
          };
        });
        return res?.[0] ? res : [];
      })
      .then(async (events) => {
        if (events?.[0]) {
          const temp: any = [];
          await Promise.all(
            events?.map(async (event: any) => {
              return event?.handles?.map((ref: any) => {
                temp.push(ref);
              });
            })
          );

          const myColabs: any = [];
          temp?.[0] &&
            (await Promise.all(
              temp?.map(async (handle: any) => {
                const data = await getUserInfoByHandle(handle);
                myColabs.push(data);
              })
            ));

          if (myColabs?.[0])
            setMyContext((existing: any) => {
              return {
                ...existing,
                myColabs: myColabs,
              };
            });
          // console.log('myColabsxxxx', temp, myColabs)
        }
      });
  }, [myContext?.myInfo?.handle]);

  return (<>

    <Helmet>
      <meta name="description" content={myContext?.pageInfo?.description || 'My Guest Networking'} />
      <meta property="og:title" content={myContext?.pageInfo?.title || 'MyGuestNet'} />
      <meta property="og:description" content={myContext?.pageInfo?.description || 'My Guest Networking'} />
      <meta property="og:image" content={myContext?.pageInfo?.picture || "https://varefiles.s3.us-east-2.amazonaws.com/MGN_logo.png"} />
      < meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={myContext?.pageInfo?.title || 'MyGuestNet'} />
      <meta property="twitter:description" content={myContext?.pageInfo?.description || 'My Guest Networking'} />
      <meta property="twitter:image" content={myContext?.pageInfo?.picture || "https://varefiles.s3.us-east-2.amazonaws.com/MGN_logo.png"} />
      <title>{myContext?.pageInfo?.title || 'My Guest Networking'}</title>
    </Helmet>
    <RecoilRoot>
      <ToastContainer />
      <TawkMessengerReactInfo propertyId="property_id" widgetId="default" />
      <div
        className="App"
        style={{
          display: "flex",
          position: "relative",
          marginTop: 0,
          marginLeft: 0
        }}
      >
        <MotionConfig transition={{ ease: DEFAULT_EASE }}>
          <UserContext.Provider value={{ myContext, setMyContext }}>
            <BrowserRouter>
              <Sidebar
              // isSidebarOpen={myContext?.isSidebarOpen}
              // setIsSidebarOpen={(e: any) => {
              //   setMyContext({
              //     ...myContext,
              //     isSidebarOpen: e
              //   })
              // }}
              />
              <div
                onMouseLeave={() => {
                  setMyContext({
                    ...myContext,
                    isMenuOpen: false,
                  });
                }}
              >
                <Menubar />
              </div>

              <Routes>
                <Route path={`/*`} />

                <Route
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/login`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />

                {/* <Route
                  path={`${process.env.PUBLIC_URL}/event/:eventId`}
                  element={<UserProfileModal />}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/share`}
                  element={<ShareHandler />}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/stories`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <StoryTemplate />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/story/:eventId/:eventTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <StoryTemplate />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/story/:eventId/:eventTab/:eventSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <StoryTemplate />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/storyCaption`}
                  element={<Suspense fallback={<div>Loading...</div>}>

                    <InstagramStory
                      caption="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa mollitia, deleniti iusto quae qui provident omnis ab, eius, fugiat modi maiores dicta sunt ad facere quia asperiores! Tempore harum magni deserunt ipsa nesciunt ullam at sapiente, est, nihil mollitia accusantium. This is a test caption that scrolls as audio plays. The background is dynamic (image or video)."
                      audioSrc="https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3"
                      img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2dV7i6tPkgxE6oWMLQB02BSL0dzWzJV5R_w&s"
                      video="https://www.w3schools.com/html/mov_bbb.mp4"
                      useImg={false} // Switch between true for image, false for video
                      useAudioSrc={true} // Custom audio if true
                      interval={10} // 10 seconds for video duration
                    />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/story/:eventId/:eventTab/:eventSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <StoryTemplate />
                  </Suspense>}
                />


                <Route
                  path={`${process.env.PUBLIC_URL}/videos`}
                  element={<MyVideoEditor />}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/event/:eventId`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <GuestPage />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/vareapp/:eventId/:eventTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <VareApp />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/newsrunner/:page/:handle/:tab/:groupName`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <NewsRunner />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/talkinghead`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    < TalkingHead text={"Hi I am Robo. The talking head avatar."} />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/event/:eventId/:eventTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <GuestPage />
                  </Suspense>}
                />
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/event/:eventId/:eventTab/:eventSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
<AppIndex />
</Suspense>}
                /> */}
                <Route
                  path={`${process.env.PUBLIC_URL}/event/:eventId/:eventTab/:eventSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <GuestPage />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/host/:hostId`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/host/:hostId/:hostTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/advert`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/advert/:advertId`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/host/:hostId/:hostTab/:hostSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/messages`}
                  element={
                    <BackDrop
                      style={
                        {
                          // backgroundColor: 'red',
                        }
                      }
                    >
                      <DetailModal isEvent={true}>
                        <SupportersList
                          directMsgGuest={myContext?.myInfo}
                          openChat={true}
                          setOpenChat={() => { }}
                        />
                      </DetailModal>
                    </BackDrop>
                  }
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/checkout`}
                  element={<CheckoutPage />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/ak-comp`}
                  element={<SideAppDrawer />}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/form`}
                  element={
                    <AdvancedForms
                    // isSidebarOpen={myContext?.isSidebarOpen}
                    // setIsSidebarOpen={(e: any) => {
                    //   setMyContext({
                    //     ...myContext,
                    //     isSidebarOpen: e
                    //   })
                    // }}
                    />
                  }
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/demography`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <AppIndex />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/task`}
                  element={<Tasks />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/guestsite`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <Guestsite />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/guestsite/:siteName`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <Guestsite />
                  </Suspense>}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/guestsite/:siteName/:siteSubTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <Guestsite />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/guestsite/:siteName/:siteSubTab/:siteItemTab`}
                  element={<Suspense fallback={<div>Loading...</div>}>
                    <Guestsite />
                  </Suspense>}
                />

                <Route
                  path={`${process.env.PUBLIC_URL}/canvas`}
                  element={<HomePage />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/canvas/:hostId`}
                  element={<RoomPage />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/calendar`}
                  element={<GoogleCalendarAPI />}
                />
              </Routes>
            </BrowserRouter>
          </UserContext.Provider>
        </MotionConfig>
      </div>
    </RecoilRoot>
  </>);
}

const ShareHandler = () => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const {
    myInfo,
    myInfo: { active = "2" },
  } = myContext;
  const navigate = useNavigate();
  const params: any = useParams<any>();

  const eventId = useMemo(() => {
    return params?.eventId;
  }, [params?.eventId, myInfo?.handle]);

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);
  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  useEffect(() => {
    (async () => {
      alert("Shate content into MyGuestNet App");
      const ruserEvent = await getDataDB({
        eventId: eventId,
        handle: myInfo?.handle,
      });

      const myEvent = ruserEvent?.[0];

      if (myEvent) {
        const formData = new FormData(document.forms[0]);

        const title = formData.get("name");
        const text = formData.get("description");
        const url = formData.get("link");
        const file = formData.get("file");

        // Handle the received data as needed
        console.log({ title, text, url, file });

        const data = {
          query: {
            title: title,
            Description: text,
            handle: myInfo?.handle,
            link: url,
            doc: file,
            eventId: eventId,
          },
          resource: "calendar_event_media",
          check: ["handle", "title"],
          myInfo,
        };
        // console.log('formData', data)
        // saveDataDB(data).then((res) => {
        //   navigate(`/event/${eventId}/media`);
        // });

        // Optionally, navigate to a different page
      } else {
        alert("Please open the event you will like to share this file into");
      }
    })();
  }, [myInfo?.handle]);

  return <div>Handling shared content...</div>;
};
