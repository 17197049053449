// import RESTCall from './restApi';
// import axios from 'axios';
// import Constants from 'expo-constants';

import moment from 'moment';

//wild search document
// http://localhost:5000/vareapp/search/
//post
// {
//     "key": ["group","number"],
//     "doc":"vare_chats",
//     "search":"51234453322"
// }

//delete document
// http://localhost:5000/vareapp/delete/
//post
// {
//     "key":["item_id","user_id"],
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "keyName": "hhhhhh"
//         }
//     ]
// }

//group votes and count response
//endppoint: http://localhost:5000/vareapp/votes
//type: post
// {
//     "doc":"vare_vote",
//     "data": ["hr6760-116","ccxxxhhhhhh","P60012143_voteMeIn"]
// }

//find all document
//endppoint: http://localhost:5000/vareapp/find/
//type: post
// {
//     "doc":"vare_vote",
//     "data": [
//         {"user_id":"Shavae2006@yahoo.com","item_id":"hr6760-116"}
//     ]
// }

//search with query document
//endppoint: http://localhost:5000/vareapp/find/
//type: post
// {
//     "doc":"vare_vote",
//     "data": [
//         {"user_id":"Shavae2006@yahoo.com","item_id":"hr6760-116"}
//     ]
// }

//update document
// http://localhost:5000/vareapp/update/
//post
// {
//     "key":["item_id","user_id"],
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "keyName": "hhhhhh"
//         }
//     ]
// }

//create single document
//endppoint: http://localhost:5000/vareapp/single/
//type: post
// {
//     "key":["user_id","item_id"],  //where user_id and item_id values does not exist
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "vote": "yes",
//             "Timestamp": "0000-00-00 00:00:00",
//             "installId": "ww7FB4E2EF-4722-48F2-9324-1B51468F39B0",
//             "keyName": ""
//         }
//     ]
// }

export default {
  axiosQuery: async (myRequest) => {
    const {
      dateName,
      gteDate,
      search,
      request,
      resource,
      id,
      searchnotnull,
      keyName,
      check,
      query,
      orderBy,
      sortBy,
      limit,
      skip,
      deviceId
    } = myRequest;
    const urlPrefix = 'https://vare-middleware.herokuapp.com';
    // const urlPrefix = "http://localhost:5003";

    const urlPrefixMiddle = 'https://vare-middleware.herokuapp.com';
    // const urlPrefixMiddle = "http://localhost:5003";

    // console.log('myRequest',myRequest)
    // var orderBy = "asc";
    var url = '';
    var data = {};
    var timestamp = `${moment().format()}`;
    var method = 'post';
    var route = '';

    var uniqueId = 'web'
    // console.log('DeviceInfo', uniqueId)


    if (resource == 'calendar_users' && request == 'register') {
      url = `https://ad2cezje3zeom4dmnbm2axkm7a0bhggz.lambda-url.us-east-2.on.aws/auth/register`;
      // url = `${urlPrefix}${'/auth/register'}`;
      query['installId'] = uniqueId?.toString() || 'tempid';

      data = {
        doc: resource,
        data: [query ? query : {}],
      };
      method = 'POST';
    } else if (resource == 'calendar_users' && request == 'login') {
      url = `https://ad2cezje3zeom4dmnbm2axkm7a0bhggz.lambda-url.us-east-2.on.aws/auth/login`;
      // url = `${urlPrefix}${'/auth/login'}`;
      data = {
        doc: resource,
        data: [query ? query : {}],
      };
      method = 'POST';
      // } else if (request == 'read' || request == 'get') {
      //   url = `${urlPrefixMiddle}${'/vareapp/findall?doc='}${resource}${sortBy ? '&sortBy=' + sortBy : ''
      //     }${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''
      //     }`;
      //   data = {};
      //   method = 'GET';
      // } else if (request == "wildsearch") {
      //   url = `${urlPrefixMiddle}/vareapp/wildsearch`;
      //   // url = `https://2d7nyriifbho7ikqe45jjiwgdy0ymlhe.lambda-url.us-east-2.on.aws/wildsearch`
      //   data = {
      //     doc: resource,
      //     data: [query ? query : {}],
      //     limit: limit || 100,
      //     skip
      //   };
      //   if (dateName && gteDate) {
      //     data["dateName"] = dateName;
      //     data["gteDate"] = gteDate;
      //   }
      //   if (search) {
      //     data["search"] = search;
      //   }
      //   if (check) {
      //     data["key"] = check;
      //   }
      //   if (sortBy) data["sortBy"] = sortBy;
      //   if (orderBy) data["orderBy"] = orderBy;
      //   method = "POST";
      // } else if (request == "wildsearchArray") {
      //   // url = `${urlPrefixMiddle}/vareapp/wildsearchArray`;
      //   url = `https://jrr5i7qu3dlx3gddoywjwbzpfi0fyfyn.lambda-url.us-east-2.on.aws/wildsearchArray`
      //   data = {
      //     doc: resource,
      //     data: [query ? query : {}],
      //     limit: limit || 100,
      //     skip
      //   };
      //   if (dateName && gteDate) {
      //     data["dateName"] = dateName;
      //     data["gteDate"] = gteDate;
      //   }
      //   if (search) {
      //     data["search"] = search;
      //   }
      //   if (check) {
      //     data["key"] = check;
      //   }
      //   if (sortBy) data["sortBy"] = sortBy;
      //   if (orderBy) data["orderBy"] = orderBy;
      //   method = "POST";
      // } else if (request == 'search') {
      //   // url = `${urlPrefixMiddle}/vareapp/search`;
      //   url = `https://4unztlukktnvz57ki3kcekvgaq0ljchu.lambda-url.us-east-2.on.aws/search`
      //   data = {
      //     doc: resource,
      //     data: [query ? query : {}],
      //     limit,
      //     skip,
      //   };
      //   if (dateName && gteDate) {
      //     data['dateName'] = dateName;
      //     data['gteDate'] = gteDate;
      //   }
      //   if (search && check) {
      //     data['search'] = search;
      //     data['key'] = check;
      //   }
      //   if (sortBy) data['sortBy'] = sortBy;
      //   if (orderBy) data['orderBy'] = orderBy;
      //   method = 'POST';
      // } else if (request == 'searchArray') {
      //   url = `${urlPrefixMiddle}/vareapp/votes`;
      //   data = {
      //     doc: 'vare_vote',
      //     data: query ? query : {},
      //   };
      //   method = 'POST';
      // } else if (request == 'insert') {
      //   //insert
      //   url = `${urlPrefixMiddle}/vareapp/update`;

      //   // url = 'http://localhost:5000/vareapp/update'
      //   query['installId'] = uniqueId?.toString() || 'tempid';
      //   data = {
      //     key: check, //where user_id and item_id values does not exist
      //     doc: resource,
      //     data: [query ? query : {}],
      //   };
      //   // console.log('ZZxxxx',data)
      //   method = 'POST';
      // } else if (request == 'patch') {
      //   //edit
      //   url = `${urlPrefixMiddle}/vareapp/update`;
      //   data = {
      //     key: check,
      //     doc: resource,
      //     data: [query ? query : {}],
      //   };
      //   method = 'post';
      // } else if (request == 'delete') {
      //   url = `${urlPrefixMiddle}${'/vareapp/delete?id='}${id}&doc=${resource}`;
      //   data = {};
      //   method = 'DELETE';
      // }

    } else if (request == 'read' || request == 'get') {
      url = `https://dxavpxtsuqpojwz2hod3p4h54u0ajsvj.lambda-url.us-east-2.on.aws${'/vareapp/findall?doc='}${resource}${sortBy ? '&sortBy=' + sortBy : ''
        }${orderBy ? '&orderBy=' + orderBy : ''}${skip ? '&skip=' + skip : ''}${limit ? '&limit=' + limit : ''
        }`;
      data = {};
      method = 'GET';
    } else if (request == 'wildsearch') {
      // url = `${urlPrefixMiddle}/vareapp/wildsearch`;
      url = `https://2d7nyriifbho7ikqe45jjiwgdy0ymlhe.lambda-url.us-east-2.on.aws/wildsearch`;
      data = {
        doc: resource,
        data: [query ? query : {}],
        limit: limit || 400,
        skip,
      };
      if (dateName && gteDate) {
        data['dateName'] = dateName;
        data['gteDate'] = gteDate;
      }
      if (search) {
        data['search'] = search;
      }
      if (check) {
        data['key'] = check;
      }
      if (sortBy) data['sortBy'] = sortBy;
      if (orderBy) data['orderBy'] = orderBy;
      method = 'POST';
    } else if (request == "search") {
      url = `https://4unztlukktnvz57ki3kcekvgaq0ljchu.lambda-url.us-east-2.on.aws/search`;
      // url = 'http://localhost:5003/vareapp/search'

      data = {
        doc: resource,
        data: [query ? query : {}],
        limit: limit || 400,
        skip
      };
      if (dateName && gteDate) {
        data["dateName"] = dateName;
        data["gteDate"] = gteDate;
      }
      if (search) {
        data["search"] = search;
      }
      if (searchnotnull) {
        data["searchnotnull"] = true;
      }
      if (check) {
        data["key"] = check;
      }
      if (sortBy) data["sortBy"] = sortBy;
      if (orderBy) data["orderBy"] = orderBy;
      method = "POST";
    } else if (request == 'searchArray') {
      url = `${urlPrefixMiddle}/vareapp/votes`;
      data = {
        doc: 'vare_vote',
        data: query ? query : {},
      };
      method = 'POST';
    } else if (request == 'insert') {
      //insert
      url = `${urlPrefixMiddle}/vareapp/update`;
      // url = 'http://localhost:5000/vareapp/update'
      // query["installId"] = 'TBD' //Constants.installationId;
      if (deviceId) {
        query["installId"] = deviceId
      }
      data = {
        key: check, //where user_id and item_id values does not exist
        doc: resource,
        data: [query ? query : {}]
      };
      method = "POST";
    } else if (request == 'patch') {
      //edit
      url = `${urlPrefixMiddle}/vareapp/update`;
      data = {
        key: check,
        doc: resource,
        data: [query ? query : {}],
      };
      method = 'post';
    } else if (request == 'delete') {
      url = `${urlPrefixMiddle}${'/vareapp/delete?id='}${id}&doc=${resource}`;
      data = {};
      method = 'DELETE';
    }

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify(data);

    var requestOptions =
      method != 'GET'
        ? {
          method: method,
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }
        : {
          method: method,
          headers: myHeaders,
          redirect: 'follow',
        };

    return fetch(url, requestOptions)
      .then(response => {
        return response.text() || 'done';
      })
      .then(result => {
        // console.log("responseresult2", result);
        if (typeof result == 'string' && !result.includes('<!DOCTYPE html>')) {
          return result && typeof result == 'string' ? JSON.parse(result) : 'done';
        } else {
          return 'done';
        }
      })
      .catch(error => {
        console.log('erroraa', error);
        return [];
      });
  },
};

// export { axiosQuery };
