import { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import data from "./data";
import "../../App.css";
import { RWebShare } from "react-web-share";
import skills_set from "src/assets/data/skills_set";
import skills_industry from "src/assets/data/skills_industry";
import axios from "axios";
import RESTCall from "../../restApi";


import Select from "react-select";

//Import calendar
// import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import { colors } from "../MyLinks";
import Search from "@mui/icons-material/Search";
// import { InputAgenda } from "../AgendaFormComponent";
import {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from "cdbreact";

import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserContext } from "src/App";
import {
  deleteDataDB,
  deleteFileDB,
  deleteLinkDB,
  deleteMediaDB,
  deleteMessageDB,
  deletePortfolioDB,
  deleteSkillDB,
  getCalendarEvents,
  getCalendarUsers,
  getChatGPTResponse,
  getContactsDB,
  getDataDB,
  getLeadsDB,
  getLinksDB,
  getMediaDB,
  getMessagesDB,
  getPictureByDescription,
  getPortfolioDB,
  getReactionDB,
  getShareDB,
  getSkillsDB,
  myColors,
  resizeImage,
  saveContactsDB,
  saveDataDB,
  saveLinkDB,
  saveMediaDB,
  saveMessageDB,
  savePortfolioDB,
  saveShareDB,
  saveSkillDB,
  scrapLink,
  sendEmail,
  stringifyArrayToBullets,
} from "src/api";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  TabContent,
  FormGroup,
} from "react-bootstrap";
import { ListItemsMain } from "../ListItemsMain";
// import colors from "react-multi-date-picker/plugins/colors";
import moment from "moment";
import { ListItems } from "../ListItems";

import Translate from "@mui/icons-material/Translate";
import EditNote from "@mui/icons-material/EditNote";
import Delete from "@mui/icons-material/Delete";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Close from "@mui/icons-material/Close";
import {
  Add,
  AddAPhoto,
  AddLink,
  Attachment,
  CheckCircle,
  CheckCircleOutline,
  ClearAll,
  CloudUpload,
  Description,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterList,
  Folder,
  InsertDriveFile,
  PlusOne,
  RemoveCircleOutline,
  Save,
  SearchSharp,
  Send,
  Share,
  ShoppingBag,
  X,
} from "@mui/icons-material";
import CarrousselCardApi from "../CarrousselCardApi";
import SupportersList, { ListMesages } from "../SupportersList";
import { ListItemIcon } from "@mui/material";
import { ShareWrapper } from "../Share";
import { AnyRecord } from "dns";
import CarrousselListCard from "../AdvertCarrousselListCard";
import AdvertCarrousselListCard from "../AdvertCarrousselListCard";
import { ButtonL } from "../AppIndex";
import YouTube from "react-youtube";
import MyImageCropper from "./MyImageCropper";
// import { IconButton } from 'material-ui';

const uploadVideoUrl =
  "https://vare-middleware.herokuapp.com/upload/myAppVideoUpload";
const uploadImgUrl =
  "https://vare-middleware.herokuapp.com/upload/myAppImageUpload";

export const MyFileUploader = (props: any) => {
  const { myContext, setMyContext } = useContext<any>(UserContext);
  const { myInfo, host, eventId, hostTabs } = myContext;
  const { editInfo, type } = props;
  const params: any = useParams<any>();
  const {
    setFormInput,
    formInput,
    handleSaveImage,
    uploadNow = false,
    refreshing,
    setRefreshing,
    resource,
    addFiletoDatabase, //call back function to handle store location of the image in the database object
    defaultImage = "", // deafault image
    allowAdd = true,
    imageKey = "img",
    aiSearchBy
    // pictureDesc
  } = props;

  const eventSubTab = useMemo(() => {
    return params?.eventSubTab;
  }, [params?.eventSubTab]);

  const eventTab = useMemo(() => {
    return params?.eventTab;
  }, [params?.eventTab]);

  const hostSubTab = useMemo(() => {
    return params?.hostSubTab;
  }, [params?.hostSubTab]);

  const hostTab = useMemo(() => {
    return params?.hostTab;
  }, [params?.hostTab]);

  // const resource = useMemo(() => {
  //   const tab = eventTab || hostTab
  //   const subTab = eventSubTab || hostSubTab
  //   return `calendar_${params.eventId ? "event" : "host"
  //     }${tab ? '_' + tab : ''}${subTab ? '_' + subTab : ''}`
  // }, [
  //   eventTab,
  //   eventSubTab,
  //   hostSubTab,
  //   hostTab,
  //   params?.eventTab
  // ]);

  // console.log("resource", resource);

  const fileImageName = useRef("");
  const fileImageData = useRef("");
  const fileImageMime = useRef("");
  const [url, setImageURL] = useState(
    "https://media.varehall.com/upload/myUpload"
  );

  const myFile = useRef<any>();
  const [selectedImage, setSelectedImage] = useState<any>('');
  const [progress, setProgress] = useState("getUpload");
  const [errorMessage, setErrorMessage] = useState("");
  // const [refreshing, setRefreshing] = useState<boolean>(false);

  const handleImageClick = () => {
    if (myFile.current) {
      myFile.current.click();
    }
  };

  useEffect(() => {
    setSelectedImage(formInput?.img || '')
  }, [
    formInput?.img
  ])


  useEffect(() => {
    (async () => {
      if (!resource) {
        alert("Resource not found");
      }

      if (uploadNow) {

        const fileName = `calendar_${fileImageName.current.toLowerCase()}`;
        const info: any = {
          handle: myInfo?.handle,
        };

        if (fileImageData.current) {
          setRefreshing(true);
          const imgData: any = {
            mime: fileImageMime.current,
            name: fileName,
            image: fileImageData.current,
          };
          // console.log('imgData', formInput, imgData)
          if (addFiletoDatabase) {
            return await axios
              .post(url, imgData)
              .then((img: any) => {
                addFiletoDatabase(
                  `https://varefiles.s3.us-east-2.amazonaws.com/${img.data && img.data.key ? img.data.key : fileName
                  }`
                );
              });
          } else {

            const imageUploader = await axios
              .post(url, imgData)
              .then((img: any) => {
                info[imageKey || "img"] = `https://varefiles.s3.us-east-2.amazonaws.com/${img.data && img.data.key ? img.data.key : fileName
                  }`;
                if (info[imageKey]) {
                  setFormInput({
                    ...formInput,
                    [imageKey]: info[imageKey]
                  })
                }
                return true;
              })
              .catch((err) => {
                return true;
              });
            if (imageUploader) {
              // alert(formInput.title)
              const data = {
                query: {
                  ...formInput,
                  ...info,
                },
                resource: resource,
                check: ["handle", "title"],
                myInfo,
              };
              // console.log("hostTabsxxccbbbb vv", data);

              saveDataDB(data).then((res) => {
                setMyContext((existing: any) => {
                  return {
                    ...existing,
                    editInfo: false,
                  };
                });
              });
            }
          }

        }
      }
    })();
  }, [uploadNow]);

  var openFile = async (file: any) => {
    var input = file.target;
    if (file) {
      var reader = new FileReader();
      reader.onload = async () => {
        var dataURL = reader.result;
        // var output: any = document.getElementById("output");
        // output.src = dataURL ? dataURL : "";
        setSelectedImage(dataURL);
        setFormInput({
          ...formInput,
          isFile: true,
        })
        // console.log('fileImageName', input?.files[0]?.name)

        const successImages: any = reader.result;

        if (!url) {
          setErrorMessage("missing a url to upload to");
          setProgress("uploadError");
          return;
        }
        setProgress("uploading");
        try {
          const name: any = input?.files[0]?.name;

          const imageString = await resizeImage(successImages);
          const image =
            name &&
              (name.includes(".png") ||
                name.includes(".jpg") ||
                name.includes(".webp") ||
                name.includes(".jpeg"))
              ? await [imageString]
              : successImages;

          const parts = successImages.split(";");
          const mime = parts[0].split(":")[1];

          // console.log('successImages', parts, mime)
          fileImageName.current = name;
          fileImageData.current = image[0];
          fileImageMime.current = mime;
          setProgress("uploaded");
        } catch (error) {
          console.log("error in upload", error);
          // @ts-ignore
          setErrorMessage(error.message);
          setProgress("uploadError");
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };


  const [myPictures, setMyPictures] = useState<any>([])
  const [savedLink, setSavedLink] = useState<any>('')
  const [loadingAI, setLoadingAI] = useState<boolean>(false)
  const [loadType, setLoadType] = useState<any>('')
  const [formInputAI, setFormInputAI] = useState<any>('')
  const [formInputImage, setFormInputImage] = useState<any>('')

  const getPics = useCallback((pictureDesc: string) => {
    if (!(pictureDesc && myInfo?.handle)) {
      alert('Please login')
      return
    }
    // alert(3 + pictureDesc)
    getPictureByDescription({
      description: pictureDesc,
      myInfo: myInfo
    })
      .then((info: any) => {
        console.log('Picture found:', info);

        if (info?.[0]?.pictures?.[0]) {
          alert('Please select from the loaded images')
          // console.log('Picture found:', info?.pictures);
          setMyPictures(info?.[0]?.pictures)
          setLoadingAI(false)
        } else {
          alert('Not available at this time, please try again')
          setLoadingAI(false)

          console.log('No picture found for the description');
        }
      }).catch(err => {
        alert('Not available at this time, please try again')
      })

  }, [
    myInfo?.handle
  ])


  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }}>


    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
        {loadType == 'AI' && imageKey != 'logo'
          ? <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                // border: '2px solid #479d83',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 20

              }}

            >
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 14,
                background: "rgb(71 157 131 / 25%)",
                margin: "10px 0px",
                position: "relative",
                borderRadius: 5


              }}>
                {loadType && <Close style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  width: 16,
                  height: 16
                }} onClick={() => {
                  setLoadType(false)
                }} />}
                <input
                  // value={data?.title}
                  className="form-control"
                  type="text"
                  onChange={(e: any) =>
                    setFormInputAI(e.target?.value)
                  }
                  value={formInputAI || ""}
                  style={{
                    border: '2px solid #479d83',
                    // marginBottom: 10,
                    width: "100%",
                    alignSelf: "center",
                    // backgroundColor: 'gray'
                  }}
                  required
                  placeholder="Generate or Search Picture"
                />
                <div
                  onClick={() => {
                    if (!(formInputAI?.length > 0)) {
                      alert(`A description is needed to generate your description`)
                      return
                    }
                    setLoadingAI(true)
                    getPics(formInputAI)
                  }}
                  style={{
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: loadingAI ? 'green' : 'transparent',
                    borderRadius: 100,
                    color: 'white',
                    paddingRight: 5,
                    paddingLeft: 5,
                    marginRight: 10,
                  }}

                >
                  {loadingAI ? 'loading..' : <Send
                    style={{
                      color: 'green',
                    }} />}
                </div>
              </div>
              {/* <div>Generate or Search Picture</div> */}
              <div>
                {myPictures?.[0]
                  && <div style={{
                    display: 'flex',
                    padding: 10,
                    width: 450,
                    height: 200,
                    overflowX: 'auto',
                    // flexDirection: '',
                    gap: 10,
                    borderRadius: 5,
                    backgroundColor: 'rgba(71, 157, 131, 0.25)'
                  }}>
                    {myPictures?.map((media: any, index: number) => {
                      return <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        gap: 5,
                        width: 150
                      }}>
                        <img
                          src={media?.src?.large} alt="" role="image"
                          style={{
                            width: '200%',
                            height: '100%',
                            objectFit: "cover"
                          }}
                          onClick={() => {
                            if (!myInfo?.handle) {
                              alert("Please login");
                              return;
                            }
                            // if (!formInput?.title) {
                            //   alert("Title required");
                            //   return;
                            // }
                            setFormInput({
                              ...formInput,
                              [imageKey]: media?.src?.large, //index?.toString()
                              ...(media?.src && Object.keys(media?.src)?.[0] ? {
                                pictures: Object.keys(media?.src)?.map(ref => media?.src[ref])
                              } : {})
                            })
                            setTimeout(() => {
                              setMyPictures([])
                              setSelectedImage('')
                            }, 100)
                          }}
                        />

                        {/* <div
                          style={{
                            margin: 0,
                            ...(!formInput?.topic ? {
                              backgroundColor: 'rgba(0,0,0,0.1)'
                            } : {}),
                            color: 'white',
                            fontSize: 12,
                            padding: 5,
                            borderRadius: 5,
                          }}
                          onClick={() => {
                            if (!myInfo?.handle) {
                              alert("Please login");
                              return;
                            }
                            if (!formInput?.title) {
                              alert("Title required");
                              return;
                            }
                            setFormInput({
                              ...formInput,
                              [imageKey]: media?.src?.large, //index?.toString()
                              ...(media?.src && Object.keys(media?.src)?.[0] ? {
                                pictures: Object.keys(media?.src)?.map(ref => media?.src[ref])
                              } : {})
                            })
                            setTimeout(() => {
                              setMyPictures([])
                              setSelectedImage('')
                            }, 100)
                          }}
                        >
                          {'Add+'}
                        </div> */}
                      </div>
                    })}
                  </div>
                }
              </div>
            </div>
          </>
          : loadType == 'link'
            ? <InputAgenda style={{
              marginTop: 10,
              marginBottom: 10
            }}>
              {loadType && <Close style={{
                position: "absolute",
                top: 5,
                right: 5,
                width: 16,
                height: 16
              }} onClick={() => {
                setLoadType(false)
              }} />}
              <input
                type={"text"}
                onChange={(e: any) => {
                  setFormInputImage(e.target?.value)
                }}
                value={formInputImage || ''}
                placeholder={"Add image url ..."}
                required
                style={{
                  border: '2px solid #479d83',
                  borderRadius: 20,
                  width: '100%'
                }}
              />
              <Send
                style={{
                  color: (formInputImage?.includes('.jpg')
                    || formInputImage?.includes('.png')
                    || formInputImage?.includes('.jpeg')) ? 'green' : 'black'
                }}
                onClick={() => {
                  if (!(formInputImage?.includes('.jpg') || formInputImage?.includes('.png') || formInputImage?.includes('.jpeg'))) {
                    alert('Oops Image url seem wrong, please try again')
                    return
                  }

                  setFormInput({
                    ...formInput,
                    [imageKey]: formInputImage
                  })
                  setLoadType(false)
                }}
              />
            </InputAgenda>
            : loadType == 'picture'
              ? <Modal
                show={true}
                onHide={() => {
                  setLoadType(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>MyGuestNet</Modal.Title>
                </Modal.Header>
                <Modal.Body> Take a picture</Modal.Body>
                <Modal.Footer
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CameraCapture
                    {...props}
                    handleFileUpload={(e: any) => {
                      openFile(e)
                    }}
                    setLoadType={setLoadType}
                    myFile={myFile}
                    setSelectedImage={setSelectedImage}
                    fileImageName={fileImageName}
                    fileImageData={fileImageData}
                    fileImageMime={fileImageMime}
                    setFormInput={setFormInput}
                    formInput={formInput}
                  />
                </Modal.Footer>
              </Modal>
              : null}
      </div>
      <InputAgenda>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: 10
          // backgroundColor: 'red',

        }}>
          <ImgWrapper style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
            {selectedImage ? (
              <img
                style={{
                  width: 80,
                  height: 80,
                  // width: '100%', height: '100%',
                  objectFit: 'cover'
                }}
                onClick={() => {
                  handleImageClick();
                  console.log("clicked");
                }}
                id="output"
                className="uploader__file"
                src={selectedImage}
              />
            ) : formInput?.[imageKey] ? (
              <img
                style={{
                  width: 80,
                  height: 80
                }}
                onClick={() => {
                  handleImageClick();
                  console.log("clicked");
                }}
                id="output"
                className="uploader__file"
                src={formInput?.[imageKey] || ''}
              />
            )
              : (
                null
              )
            }

            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: 50,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div onClick={() => setLoadType('link')} style={{
                height: 30,
                width: 30
              }}>
                <AddLink />
              </div>
              <div
                onClick={() => {
                  setLoadType('upload')
                  setTimeout(() => {
                    handleImageClick()
                  }, 50);

                }}
                style={{
                  height: 30,
                  width: 30
                }}>
                <Folder />
              </div>
              <div onClick={() => setLoadType('picture')} style={{
                height: 30,
                width: 30
              }}>
                <AddAPhoto />
              </div>
              {imageKey != 'logo' && <div onClick={() => setLoadType('AI')} style={{
                height: 30,
                width: 30,
                display: 'flex',
                borderRadius: 100,
                backgroundColor: myColors?.brown,
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                AI
              </div>}
            </div>
            <input
              ref={myFile}
              className="form-control"
              type="file"
              name={"file"}
              onChange={(e: any) => {
                openFile(e);
              }}
            />
          </ImgWrapper >
          {
            allowAdd && <div onClick={() => handleSaveImage()}>
              <Add />
            </div>
          }
        </div>

      </InputAgenda >
    </div>
  </div>
}


export const CameraCapture = (props: any) => {
  const {
    handleFileUpload,
    myFile,
    setLoadType,
    setSelectedImage,
    fileImageName,
    setFormInput,
    formInput,
    fileImageData,
    fileImageMime
  } = props
  const [image, setImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing camera", error);
    }
  };

  // useEffect(() => {
  //   captureImage()
  // }, [
  // ])

  const captureImage = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL("image/png");
      setImage(imageData);
      setSelectedImage(imageData) //new
      // Convert base64 to a file and set in input field
      fetch(imageData)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "captured_image.png", { type: "image/png" });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);

          if (fileInputRef.current) {
            fileInputRef.current.files = dataTransfer.files;
            myFile.current.files = dataTransfer.files;
            // handleFileUpload(dataTransfer.files)
          }
        });
    }
  };

  const urlToFile = async (imageSrc: string, filename: string, mimeType: string) => {
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: mimeType });
    return file;
  };



  const srcToBlob = async (imageSrc: any) => {

    // Usage:
    urlToFile(imageSrc, 'image.jpg', 'image/jpeg').then((file) => {
      // Here you have the `file` object similar to what `input.files[0]` would be
      console.log(file);

      // If you want to mock an input field
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      const inputElement: any = document.querySelector('input[type="file"]');
      if (inputElement) {
        inputElement.files = dataTransfer.files;  // Assign the new file object
        console.log(inputElement.files); // Now input.files contains the created file
        //---
        var reader = new FileReader();
        reader.onload = async () => {
          var dataURL = reader.result;
          // var output: any = document.getElementById("output");
          // output.src = dataURL ? dataURL : "";
          setSelectedImage(dataURL);
          setFormInput({
            ...formInput,
            isFile: true,
          })
          // console.log('fileImageName', input?.files[0]?.name)

          const successImages: any = reader.result;

          // if (!url) {
          // setErrorMessage("missing a url to upload to");
          // setProgress("uploadError");
          // return;
          // }
          // setProgress("uploading");
          try {
            const name: any = inputElement?.files[0]?.name;

            const imageString = await resizeImage(successImages);
            const image =
              name &&
                (name.includes(".png") ||
                  name.includes(".jpg") ||
                  name.includes(".webp") ||
                  name.includes(".jpeg"))
                ? await [imageString]
                : successImages;

            const parts = successImages.split(";");
            const mime = parts[0].split(":")[1];

            // console.log('successImages', parts, mime)
            fileImageName.current = name;
            fileImageData.current = image[0];
            fileImageMime.current = mime;
            // setProgress("uploaded");
          } catch (error) {
            // console.log("error in upload", error);
            // setErrorMessage(error.message);
            // setProgress("uploadError");
          }
        };
        reader.readAsDataURL(inputElement.files[0]);
        setTimeout(() => {
          setLoadType(false)

        }, 200);
      }
    });

  };

  // Helper function to load an image from src
  // const loadImage = (src: any) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.crossOrigin = "anonymous"; // This avoids CORS issues for cross-origin images
  //     img.onload = () => resolve(img);
  //     img.onerror = (err) => reject(err);
  //     img.src = src;
  //   });
  // };


  return (
    <div>
      <h2>Capture Picture</h2>
      <video ref={videoRef} width="320" height="240" style={{ border: "1px solid black" }} />
      <br />
      <button onClick={startCamera}>Start Camera</button>
      <button onClick={captureImage}>Capture Image</button>
      <br />
      <MyImageCropper
        imageSrc={image}
        setImage={setImage}
        onCropComplete={(e: any) => {
          console.log('bbbbbb', e)
          setImage(e)
          setSelectedImage(e) //new
          srcToBlob(e)


        }}
      />
      {/* {image && <img src={image} alt="Captured" style={{ marginTop: "10px" }} />} */}
      {/* <br /> */}
      {/* <input ref={fileInputRef} type="file" accept="image/*" /> */}
    </div>
  );
};



export const InputAgenda = styled.form`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px;
      border: 1px solid #dee2e6;
      border-radius: 5px;
      background: #fff;
      input {
        outline: none;
      border: none;
      font-size: 14px;
      width: 100%;
  }
      button {
        border: none;
      background: transparent;
      outline: none;
      color: gray;
      transition: color 0.3s;
      svg {
        width: 20px;
      height: 20px;
    }
      &:hover {
        color: #dee2e6;
    }
  }
      `;

const LinkParent = styled.div`
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      `;

const LinkC = styled.a`
      width: 80px;
      height: 80px;
      svg {
        width: 100%;
      height: 100%;
  }
      `;

const UploadImg = styled.div`
      background: #a0ddcb;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        color: white;
      width: 40px;
      height: 40px;
  }
      input {
        display: none;
  }
      `;
const ImgWrapper = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        display: none;
  }

      img {
        width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
  }
      `;
const FormWrapper = styled(FormGroup)`
      display: flex;
      min-width: 300px;
      flex-direction: column;
      gap: 10px;
      `;

